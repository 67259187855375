import  React, {useState, useEffect} from "react";
import {ThemeProvider} from "@material-ui/core/styles";
import { createTheme } from '@material-ui/core/styles'
import { FormControl, MenuItem, Select, Card, CardContent, Paper} from '@material-ui/core';
import InfoBox from './InfoBox';
import Map from './Map';
import Table from './Table';
import LineGraph from "./LineGraph";
import LineGraphDate from "./LineGraphDate";
import "leaflet/dist/leaflet.css";
import './App.css';
import { sortData, prettyPrintStat } from "./util";
// import DarkMode from "./DarkMode";
import CssBaseline from '@material-ui/core/CssBaseline';



function App() {
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("worldwide");
  const [countryInfo, setCountryInfo] = useState({});
  const [tableData, setTableData] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: 34.80746, lng: -40.4796 });
  const [mapZoom, setMapZoom] = useState(3);
  const [mapCountries, setMapCountries] = useState([]);
  const [casesType, setCasesType] = useState("cases");
  const [graphDate, setGraphDate] = useState(120);

  useEffect(()=>{
    const getMenuItem = async ()=>{
      await fetch('https://disease.sh/v3/covid-19/all')
      .then(response => response.json())
      .then(data =>{
        setCountryInfo(data);
      })      

  };
  getMenuItem();
  }, [])

  useEffect(() => {
    const getCountriesData = async () =>
     {
      await fetch("https://disease.sh/v3/covid-19/countries")
      .then((res) => res.json())
      .then((data) => {
        const countries = data.map((country) => ({
            name: country.country,
            value: country.countryInfo.iso2
          }));
          const sortedData = sortData(data);
          setTableData(sortedData);
          setMapCountries(data);
          setCountries(countries);


      });
    };
    getCountriesData();
  }, []);

const onCountryChange = async (event) =>{
  const countryCode = event.target.value;
  setCountry(countryCode);
  const url = countryCode === 'worldwide' ? 'https://disease.sh/v3/covid-19/all' :
  `https://disease.sh/v3/covid-19/countries/${countryCode}`;

  await fetch (url)
  .then(response => response.json())
  .then(data => {
        setCountry(countryCode);
        setCountryInfo(data);
        if (countryCode ==='worldwide')
          {setMapCenter({ lat: 34.80746, lng: -40.4796 });
          setMapZoom(3);}
        else
          {setMapCenter([data.countryInfo.lat, data.countryInfo.long]);
          setMapZoom(4);}

  });

};

const Theme = createTheme({
  palette: {
    type: 'light',
  },
});

  return (
    <ThemeProvider theme = {Theme}>
    <div className="app">
      
      {/* <Paper className=".app__paper"> */}
      <div className="app__left">
      <div className="app__header">
        <h1>COVID-19 TRACKER</h1>
        {/* <DarkMode className="app__dm"/> */}
        <FormControl className="app__dropdown">
          <Select 
            variant="outlined" 
            onChange={onCountryChange} 
            value={country} >

            <MenuItem value="worldwide">Worldwide</MenuItem>
            {countries.map((country) => ( 
              <MenuItem value={country.value}>{country.name}</MenuItem>

            ) )}
          </Select>
        </FormControl>
      </div>
      

      <div className="app__stats">
        <InfoBox 
          isRed
          active={casesType === "cases"}
          onClick={(e)=>setCasesType('cases')} 
          title="Coronavirus Cases" 
          cases={prettyPrintStat(countryInfo.todayCases)} 
          total={prettyPrintStat(countryInfo.cases)}></InfoBox>
        <InfoBox  
          active={casesType === "recovered"}
          onClick={(e)=>setCasesType('recovered')} 
          title="Recovered" 
          cases={prettyPrintStat(countryInfo.todayRecovered)} 
          total={prettyPrintStat(countryInfo.recovered)}></InfoBox>
        <InfoBox 
          isRed
          active={casesType === "deaths"}
          onClick={(e)=>setCasesType('deaths')} 
          title="Deaths" 
          cases={prettyPrintStat(countryInfo.todayDeaths)} 
          total={prettyPrintStat(countryInfo.deaths)}></InfoBox>
       
      </div>
      <Map 
                countries={mapCountries}
                casesType={casesType}
                center={mapCenter}
                zoom={mapZoom}
      />
      </div>       

      <Card className="app__right">
        <CardContent>

          <h3>Live Cases by Country</h3>

          <Table countries={tableData} />

          <h3 className ="app__graphTitle">Worldwide new {casesType}</h3>
          <div className="app__graphDate">
              <LineGraphDate 
                active ={graphDate===7}
                onClick={(e)=>setGraphDate(7)} 
                title ="7"/>
              <LineGraphDate 
                active ={graphDate===30}
                onClick={(e)=>setGraphDate(30)} 
                title ="30"/>
              <LineGraphDate 
                active ={graphDate===120}
                onClick={(e)=>setGraphDate(120)} 
                title ="120"/>
              

          </div>

          <LineGraph className="app__graph" casesType={casesType} graphDate={graphDate}/>

      </CardContent>
      </Card>
      {/* </Paper> */}
      
  </div>
  </ThemeProvider>
  );
}

export default App;