import React from 'react';
import { Card ,CardContent,Typography} from '@material-ui/core';
import './LineGraphDate.css';

function LineGraphDate({title,active,...props}) {
    return (
        <Card 
        onClick={props.onClick}
            className={`lineGraphDate ${active && "lineGraph--selected"}`}>
            <CardContent>
            
            <h4 className={`lineGraphDate--title`}>{title}</h4>
            </CardContent>
        </Card>
            
    )
}

export default LineGraphDate
